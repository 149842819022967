import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		title: '首页',
		redirect: '/home',
	},
	{
		path: '/home',
		name: '首页',
		layout: "dashboard",
		component: () => import('../views/home/Home.vue'),
	},
	{
		path: '/config-content',
		name: '配置首页',
		layout: "dashboard",
		component: () => import('../views/setting/ConfigContent.vue'),
	},
	// {
	// 	path: '/test',
	// 	name: 'Test',
	// 	title:'测试',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Test.vue'),
	// },
	// {
	// 	path: '/register',
	// 	name: 'Register',
	// 	title:'注册',
	// 	component: () => import('../views/login/Register.vue'),
	// },
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/login/Login.vue'),
	},
	{
		path: '/follow',
		name: '关注',
		layout: "dashboard",
		component: () => import('../views/follow/Follow.vue'),
	},
	{
		path: '/classify',
		name: '板块管理',
		layout: "dashboard",
		component: () => import('../views/classify/Classify.vue'),
	},
	// {
	// 	path: '/summary_open',
	// 	name: 'SummaryOpen',
	// 	title:'汇总-开放',
	// 	component: () => import('../views/summary/Summary_open.vue'),
	// },
	{
		path: '/summary',
		name: '看板',
		layout: "dashboard",
		component: () => import('../views/summary/Summary.vue'),
	},
	{
		path: '/setting',
		name: '设置',
		layout: "dashboard",
		component: () => import('../views/setting/Setting.vue'),
	},
	{
		path: '/user',
		name: '用户管理',
		layout: "dashboard",
		component: () => import('../views/user/User.vue'),
	},
	{
		path: '/history-username',
		name: '改名历史查询',
		layout: "dashboard",
		component: () => import('../views/historyUsername/historyUsername.vue'),
	}
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	// 获取 token，假设 token 保存在 localStorage 中
	const token = localStorage.getItem('token')
  
	// 检查目标路由是否需要登录
	if (to.name !== 'Login' && to.name !== 'Register' && !token) {
	  // 如果用户没有 token 且目标路由不是登录或注册页面，则重定向到登录页面
	  next({ name: 'Login' })
	} else {
	  // 继续访问
	  next()
	}
  })

export default router
