<template>
	<!-- Main Sidebar -->
	<a-layout-sider collapsible class="sider-primary" breakpoint="lg" collapsed-width="0" width="250px"
		:collapsed="sidebarCollapsed" @collapse="$emit('toggleSidebar', !sidebarCollapsed)" :trigger="null" :class="[
			'ant-layout-sider-' + sidebarColor,
			'ant-layout-sider-' + sidebarTheme,
		]" theme="light" :style="{ backgroundColor: 'transparent' }">
		<div class="brand">
			<svg t="1703343415400" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
				p-id="4215" width="32" height="32">
				<path
					d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m215.3 337.7c0.3 4.7 0.3 9.6 0.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2-50.8-10.3-88.9-55-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1-30.9-20.6-49.5-55.3-49.5-92.4 0-20.7 5.4-39.6 15.1-56 54.7 67.4 136.9 111.4 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z"
					fill="#1296DB" p-id="4216"></path>
			</svg>
			<span>X Sum</span>
		</div>
		<hr />
		<!-- Sidebar Navigation Menu -->
		<a-menu theme="light" mode="inline">
			<a-menu-item>
				<router-link to="/home">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">首页</span>
				</router-link>
			</a-menu-item>
			<a-menu-item v-if="user_info.role != 'free'">
				<router-link to="/classify">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">板块</span>
				</router-link>
			</a-menu-item>
			<a-menu-item v-if="user_info.role != 'free'">
				<router-link to="/follow">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">关注</span>
				</router-link>
			</a-menu-item>

			<a-menu-item v-if="user_info.role != 'free'">
				<router-link to="/summary">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">看板</span>
				</router-link>
			</a-menu-item>

			<a-menu-item v-if="user_info.role != 'free'">
				<router-link to="/history-username">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">改名历史查询</span>
				</router-link>
			</a-menu-item>

			<a-menu-item v-if="user_info.role != 'free'">
				<router-link to="/setting">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">设置</span>
				</router-link>
			</a-menu-item>

			
			<a-menu-item v-if="user_info.role == 'admin'||user_info.role == 'customer'">
				<router-link to="/user">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">用户管理</span>
				</router-link>
			</a-menu-item>
			<a-menu-item v-if="user_info.role == 'admin'">
				<router-link to="/config-content">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">内容配置</span>
				</router-link>
			</a-menu-item>
		</a-menu>
		<!-- / Sidebar Navigation Menu -->
		<!-- 用户信息 -->
		<!-- 登出 -->
		<div class="user-info">
			<!-- <a-avatar :size="64" icon="user" /> -->
			<div class="line-1">
				<div class="user-avatar">
					<a-icon type="user" theme="outlined" :style="{ fontSize: '28px', color: '#1890FF' }" />
				</div>
				<div class="user-address">{{ user_info.address }}</div>
			</div>
			<div class="line-2">
				<span class="user-time" v-if="user_info.role =='vip'">有效期至：{{ user_info.expirationDate }}</span>
				<span class="user-time" v-else-if="user_info.role =='free'">免费用户</span>
				<!-- <span class="xf-btn">续费</span> -->
			</div>
			<div class="btn" @click="userLogout">退出</div>
		</div>
	</a-layout-sider>
	<!-- / Main Sidebar -->
</template>

<script>
export default {
	components: {
	},
	props: {
		// Sidebar collapsed status.
		sidebarCollapsed: {
			type: Boolean,
			default: false,
		},

		// Main sidebar color.
		sidebarColor: {
			type: String,
			default: "primary",
		},

		// Main sidebar theme : light, white, dark.
		sidebarTheme: {
			type: String,
			default: "light",
		},
	},
	data() {
		return {
			user_info: {}
			// sidebarCollapsedModel: this.sidebarCollapsed,
		};
	},
	mounted() {
		this.user_info = JSON.parse(localStorage.getItem('user_info'));
		
		this.user_info.expirationDate = new Date(this.user_info.expirationDate).toLocaleDateString();
		this.user_info.address = this.truncateMiddle(this.user_info.walletAddress, 7, 5);
	},
	methods: {
		userLogout() {
			localStorage.setItem('token', '');
			localStorage.setItem('user_info', '');
			window.location = 'https://xsum.pro/#/login'
		},
		truncateMiddle(str, frontLen, endLen) {
			const totalLen = frontLen + endLen;
			if (str.length <= totalLen) {
				return str;
			}
			const start = str.slice(0, frontLen);
			const end = str.slice(-endLen);
			return `${start}...${end}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.user-info {
	border-radius: 6px;
	width: calc(100% - 40px);
	padding: 20px;
	position: absolute;
	bottom: 50px;
	background-image: linear-gradient(310deg, rgb(33 82 255 / 65%), rgb(33 212 253 / 65%));

	.line-1 {
		display: flex;
		align-items: center;
		padding-bottom: 10px
	}

	.line-2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 10px;

		.user-time {
			color: #333;
			font-size: 12px;
			display: flex;
		}

		.xf-btn {

			color: #fff;
			font-size: 14px;
			display: flex;
			cursor: pointer;
		}
	}

	.user-avatar {
		width: 40px;
		height: 40px;
		background: #fff;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.user-address {
		color: #fff;
		font-weight: 600;
		font-size: 16px;
		display: flex;
		margin-left: 10px;
	}

	.btn {
		height: 32px;
		border-radius: 6px;
		color: rgb(52, 71, 103);
		box-shadow: rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
		background: #fff;

		display: flex;
		align-items: center;
		justify-content: center;

		cursor: pointer;
	}
}
</style>